<template>
  <div>
    <h2>{{announcement.announcementTitle}}</h2>
    <AnnouncementViewCore :announcement-data="announcement" />
  </div>
</template>

<script>
import {ref, onBeforeMount} from "vue";
import axios from "axios";
import {getAnnouncementByUuid} from "@/Helpers/ApiCalls/AnnouncementAPICalls";
import store from "@/store";
import AnnouncementViewCore from "@/components/Announcements/AnnouncementViewCore.vue";

export default {
  name: "ViewAnnouncement",
  components: {AnnouncementViewCore},
  props: {
    announcementUuid: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const announcement = ref({});
    const authToken = store.getters['auth/authenticated'];

    onBeforeMount(() => {
      getAnnouncement();
    })

    async function getAnnouncement() {
      await axios.get(getAnnouncementByUuid(props.announcementUuid), {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((result) => {
        announcement.value = result.data;
        announcement.value = result.data;
        announcement.value.activeStartDate = new Date(result.data.activeStartDate);
        announcement.value.expirationDate = new Date(result.data.expirationDate);
      })
    }

    return {
      announcement
    }
  }
}
</script>

<style scoped>

</style>