import {API_URL} from "../../../Constants";

const BASE_ANNOUNCEMENT_URI = `${API_URL}/announcements`;

export const getActiveAnnouncements = () => {
    return `${BASE_ANNOUNCEMENT_URI}/active`;
}

export const getInactiveAnnouncements = () => {
    return `${BASE_ANNOUNCEMENT_URI}/inactive`;
}

export const getAnnouncementByUuid = (uuid) => {
    return `${BASE_ANNOUNCEMENT_URI}/${uuid}`;
}

export const createAnnouncement = () => {
    return `${BASE_ANNOUNCEMENT_URI}/create`;
}

export const editAnnouncement = (uuid) => {
    return `${BASE_ANNOUNCEMENT_URI}/edit/${uuid}`;
}

export const deleteAnnouncement = (uuid) => {
    return `${BASE_ANNOUNCEMENT_URI}/delete/${uuid}`;
}

export const allAnnouncements = () => {
    return `${BASE_ANNOUNCEMENT_URI}/all`;
}