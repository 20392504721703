<template>
  <div class="card" style="max-height: 500px; overflow-y: scroll; padding: 1%; box-shadow: 2px 2px 2px 2px;">
    <div v-html="announcementData.announcementText"></div>
  </div>
</template>

<script>
export default {
  name: "AnnouncementViewCore",
  props: {
    announcementData: {
      type: Object,
      required: true
    }
  },
  setup(props) {

  }
}
</script>

<style scoped>

</style>